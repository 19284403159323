import {
  Box, Button, Card, CardBody, CardHeader, Footer, Form, FormField, Grid, Grommet, Header, Heading, Layer, MaskedInput, Page, PageContent,
  PageHeader, Select, Text, TextArea
} from 'grommet'
import { Add, CircleInformation, Close, Edit, FormTrash, Share, Trash } from 'grommet-icons'
import { hpe as theme } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils'
import React, { useState } from 'react'
import { isUnused } from './Calculations'
import { getShareUrl } from './Import'
import { Trip } from './Trip'

export function Home(props) {
  const [openTripEditor, setOpenTripEditor] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [openDelConf, setOpenDelConf] = useState(false)
  const [openShareTrip, setOpenShareTrip] = useState(false)
  const [shareUrl, setShareUrl] = useState("")
  const [copied, setCopied] = useState(false)

  const tripOptions = Object.entries(props.trips).map(([tripId, trip]) => {
    return {
      id: tripId,
      label: (
        <PageHeader
          size="small"
          alignSelf="center"
          pad="5px 10px"
          title={trip.name ? trip.name : "Trip"}
          subtitle={trip.desc}
        />
      ),
      searchText: trip.name + ' ' + trip.desc
    }
  })

  const [filteredTripOptions, setFilteredTripOptions] = useState(null)
  const unusedTaxOptions = getUnusedTaxOptions()

  function getUnusedTaxOptions() {
    const unused = new Set(Object.keys(props.tax))
    for (const receiptId in props.receipts) {
      for (const tax of props.receipts[receiptId].taxes) {
        if (unused.has(tax)) {
          unused.delete(tax)
        }
        if (unused.size === 0) return unused
      }
    }

    return unused
  }


  function handleOpenTripEditor() {
    setOpenTripEditor(true)
  }

  function handleCloseTripEditor() {
    setOpenTripEditor(false)
  }

  function handleOpenDelConf() {
    setOpenDelConf(true)
  }

  function handleCloseDelConf() {
    setOpenDelConf(false)
  }

  function handleOpenInfo() {
    setOpenInfo(true)
  }

  function handleCloseInfo() {
    setOpenInfo(false)
  }

  function handleOpenShareTrip() {
    setOpenShareTrip(true)
    setShareUrl(getShareUrl(
      props.dataVersion,
      props.tax,
      props.trips[props.tripId],
      props.persons,
      props.receipts,
      props.items,
      props.shareRatios,
      props.dataVersion
    ))
  }

  function handleCloseShareTrip() {
    setOpenShareTrip(false)
    setCopied(false)
  }

  const myTheme = {
    global: {
      colors: {
        background: "#1a1a1a",
        "background-front": "#282a35",
        focus: "grey",
        brand: "#967272",
        "brand-contrast": "#dfa8a8",
        "selected-background": "brand"
      }
    },
    box: {
      responsiveBreakpoint: "xsmall",
    },
    layer: {
      responsiveBreakpoint: "xsmall",
    },
    button: {
      primary: {
        background: {
          color: "brand"
        }
      },
      secondary: {
        border: {
          color: "brand"
        }
      }
    },
    checkBox: {
      border: {
        color: "brand"
      },
      icon: {
        extend: `background-color: pink`
      }
    },
    tab: {
      active: {
        background: "brand"
      },
      hover: {
        background: "brand-contrast"
      }
    }
  }


  return (
    <Grommet full theme={deepMerge(theme, myTheme)} themeMode="dark">
      <Page kind="full">
        <PageContent border={{ "side": "bottom" }}>
          {openTripEditor && (
            <Layer
              position="right"
              full="vertical"
              modal
              onClickOutside={handleCloseTripEditor}
              onEsc={handleCloseTripEditor}
            >
              <Box
                overflow="auto"
                width="medium"
                pad="medium"
              >
                <Box flex={false} direction="row" justify="between">
                  <Heading level={2} margin="none">
                    Trip
                  </Heading>
                  <Button icon={<Close />} id="closeTripEditor" onClick={handleCloseTripEditor} />
                </Box>
                <Box flex="grow" overflow="auto" pad={{ vertical: 'medium' }}>
                  <Form
                    value={props.trips[props.tripId]}
                    onChange={props.onTripChange}
                    data-testid="tripEditorForm"
                  >
                    <FormField
                      label="Name"
                      htmlFor="tripName"
                      id="tripName"
                      name="name"
                    />
                    <FormField
                      label="Description"
                      htmlFor="tripDesc"
                      id="tripDesc"
                      name="desc"
                    />
                  </Form>
                </Box>
                <Box flex="grow" overflow="auto" pad={{ vertical: 'medium' }}>
                  <Heading level={2} margin={{ "bottom": "none" }}>
                    Tax Options
                  </Heading>
                  <Text margin={{ "bottom": "medium" }}>
                    The options are available across all trips.
                  </Text>
                  <Form
                    data-testid="taxOptionsForm"
                  >
                    {Object.keys(props.tax).map((taxId, oIdx) => (
                      <Grid columns={["flex", "55px", "40px"]} gap="small" key={taxId}>
                        <FormField
                          label="Label"
                          name={`options[${oIdx}].label`}
                          value={props.tax[taxId].label}
                          htmlFor={`options[${oIdx}].label`}
                          id={`options[${oIdx}].label`}
                          onChange={(e) => props.onTaxOptionChange(e, taxId, "label")}
                        />
                        <FormField
                          label="Value (%)"
                          htmlFor={`options[${oIdx}].value`}
                        >
                          <MaskedInput
                            name={`options[${oIdx}].value`}
                            value={props.tax[taxId].value}
                            id={`options[${oIdx}].value`}
                            onChange={(e) => props.onTaxOptionChange(e, taxId, "value")}
                            mask={[
                              {
                                regexp: /^([0-9]$|[1-9][0-9]+$)/,
                                placeholder: '8',
                              },
                              { fixed: '.' },
                              {
                                regexp: /^[0-9]{0,5}$/,
                                placeholder: '5',
                              }
                            ]}
                            textAlign="end"
                          />
                        </FormField>
                        <Button
                          data-testid="deleteTaxOption"
                          disabled={!isUnused(taxId, unusedTaxOptions)}
                          onClick={() => props.onTaxOptionDelete(taxId)}
                          icon=<FormTrash />
                        />
                      </Grid>
                    ))}
                    <Box>
                      <Button
                        label="Add"
                        data-testid="addTaxOption"
                        onClick={() => props.onTaxOptionAdd()}
                        icon=<Add />
                      />
                    </Box>
                  </Form>
                </Box>
                <Box flex={false} as="footer" align="start">
                  <Button
                    type="submit"
                    label="Done"
                    onClick={handleCloseTripEditor}
                    primary
                    margin={{ "top": "small" }}
                  />
                </Box>
              </Box>
            </Layer>
          )}
          {openDelConf && (
            <Layer
              id="deleteConf"
              position="center"
              onClickOutside={handleCloseDelConf}
              onEsc={handleCloseDelConf}
            >
              <Box pad="medium" gap="small" width="medium">
                <Heading level={3} margin="none">
                  Confirm
                </Heading>
                <Text>Are you sure you want to delete "{props.trips[props.tripId].name}" trip?</Text>
                <Box
                  as="footer"
                  gap="small"
                  direction="row"
                  align="center"
                  justify="end"
                  pad={{ top: 'medium', bottom: 'small' }}
                >
                  <Button label="Cancel" id="cancel" onClick={handleCloseDelConf} color="dark-3" />
                  <Button
                    label={
                      <Text color="white">
                        <strong>Delete</strong>
                      </Text>
                    }
                    onClick={() => {
                      props.onTripDelete()
                      handleCloseDelConf()
                    }}
                    primary
                    color="status-critical"
                  />
                </Box>
              </Box>
            </Layer>
          )}
          {openInfo && (
            <Layer
              id="info"
              position="center"
              onClickOutside={handleCloseInfo}
              onEsc={handleCloseInfo}
            >
              <Card>
                <CardHeader>
                  <Text weight="bold" size="xxlarge">
                    Welcome to BillSplit! 🙌
                  </Text>
                  <Button icon={<Close />} id="closeInfo" onClick={handleCloseInfo} />
                </CardHeader>
                <CardBody>
                  <Text size="large">
                    Have friends? Spent money together? Worrying about that one piece of 🤏 fried chicken you want to pay fairly for?<br />
                    Worry not! BillSplit is for you.<br /><br />

                    How to use:
                    <ol type="1">
                      <li>Click <Edit /> and set a name and for your <b>Trip</b> &ndash; a collection of expenses.</li>
                      <li>Customise or set up more percentage-<b>Tax Options</b> for your expenses. These options will be available for all your trips.</li>
                      <li>Tax options are <b>multiplied</b>, not added. E.g. Select 10% and 7% adds 17.7%, not 17%.</li>
                      <li>Add all participants for the trip in the <b>Persons</b> box.</li>
                      <li>Fill an expense in the <b>Expense</b> box.</li>
                      <li>Click a person in the <b>Expense</b> box to toggle whether they are sharing.</li>
                      <li>Use <strike>your TAICHI skills</strike> the <b>Ratio</b> field to split the expense unevenly.</li>
                      <li>Click <b>Add Another</b> to add another expense, otherwise you're done!</li>
                      <li>Review the expenses in the <b>Expenses</b> and <b>Who Paid</b> tabs. Click an expense to edit it.</li>
                      <li>Check the <b>Summary</b> tab for SETTLEMENT.</li>
                      <li>Click <Share /> to share the current trip with your friends. The trip gets imported on accessing the link.</li>
                      <li>Click <Add /> to add a new trip for another glorious calculation goodness.</li>
                    </ol>

                    All data are computed by your browser (there is no backend server), and automatically saved to your browser's local storage.
                  </Text>
                </CardBody>
              </Card>
            </Layer>
          )}
          {openShareTrip && (
            <Layer
              id="shareTrip"
              position="center"
              onClickOutside={handleCloseShareTrip}
              onEsc={handleCloseShareTrip}
            >
              <Box pad="medium" gap="small" width="large" height="medium">
                <Heading level={3} margin="none">
                  Share "{props.trips[props.tripId].name}"
                </Heading>
                <TextArea fill resize={false} value={shareUrl} />
                <Box
                  as="footer"
                  gap="small"
                  direction="row"
                  align="center"
                  justify="end"
                  pad={{ top: 'medium', bottom: 'small' }}
                >
                  <Button label={copied ? "Copied!" : "Copy"} id="copy" onClick={() => {
                    navigator.clipboard.writeText(shareUrl)
                    setCopied(true)
                  }} secondary />
                  <Button label="Close" id="cancel" onClick={handleCloseShareTrip} primary />
                </Box>
              </Box>
            </Layer>
          )}
          <Header wrap direction="row-responsive" pad="small">
            <Text weight="bold" size="3xl">
              BillSplit
            </Text>
            <Select
              placeholder="Trip"
              name="tripSelector"
              labelKey="label"
              valueKey={{ key: "id", reduce: true }}
              value={props.tripId}
              options={filteredTripOptions ? filteredTripOptions : tripOptions}
              onChange={({ value: nextValue }) => {
                props.setTripId(nextValue)
              }}
              onClose={() => setFilteredTripOptions(null)}
              searchPlaceholder="Enter partial name or description..."
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, 'i')
                const filtered = tripOptions.filter((o) => exp.test(o.searchText))
                setFilteredTripOptions(filtered)
              }}
            />
            <Box direction="row" gap="small">
              <Button
                onClick={() => handleOpenInfo()}
                icon={<CircleInformation />}
              />
              <Button
                onClick={() => {
                  handleOpenTripEditor()
                }}
                icon=<Edit />
              />
              <Button
                onClick={() => {
                  props.onTripAdd()
                  handleOpenTripEditor()
                }}
                icon=<Add />
              />
              <Button
                onClick={() => handleOpenDelConf()}
                icon=<Trash />
              />
              <Button
                onClick={() => handleOpenShareTrip()}
                icon=<Share />
              />
              {/* <Menu disabled icon={<User />} items={[{ "label": "sign out" }]} /> */}
            </Box>
          </Header>
        </PageContent>
        <Trip
          trip={props.trips[props.tripId]}
          persons={props.persons}
          receipts={props.receipts}
          items={props.items}
          shareRatios={props.shareRatios}
          tax={props.tax}


          onTripChange={props.onTripChange}

          onPersonAdd={props.onPersonAdd}
          onPersonNameChange={props.onPersonNameChange}
          onPersonDelete={props.onPersonDelete}

          onReceiptAdd={props.onReceiptAdd}
          onReceiptChange={props.onReceiptChange}

          onItemAdd={props.onItemAdd}
          onItemChange={props.onItemChange}
          onItemDelete={props.onItemDelete}

          onItemIdChange={props.onItemIdChange}
          onShareRatioChange={props.onShareRatioChange}
        />
      </Page>
      <Footer direction="row-responsive" pad="medium">
        <Text margin="auto 30px">&copy; 2022-2023 Choy Shi Hong.</Text>
        <Box direction="row-responsive">
          <a className="footer-link" href="mailto:hello@shiister.xyz">Email</a>
          <a className="footer-link" href="https://blog.shiister.xyz">Blog</a>
          <a className="footer-link" href="https://www.linkedin.com/in/choy-shi-hong">LinkedIn</a>
          <a className="footer-link" href="https://github.com/MrShiister">GitHub</a>
        </Box>
        <Text margin="auto 30px">v0.3.9</Text>
      </Footer>
    </Grommet>
  )
}
