import {
  Button, Grid, MaskedInput, Text
} from 'grommet';
import React from 'react';
import { addTaxes, calculateCost, round } from './Calculations';


export function ShareRatio(props) {
  function setEveryone() {
    const sharers = Object.keys(props.shareRatio)
    for (const personId of props.trip.personIds) {
      if (!sharers.includes(personId) || +props.shareRatio[personId] === 0) {
        props.onShareRatioChange({target: {value: 1}}, personId)
      }
    }
  }

  const cost = addTaxes(+props.item.baseCost, props.receipt.taxes, props.tax)

  return (
    <>
      <Text textAlign="start" weight="bold">
        Who's Sharing?
      </Text>
      <Grid columns={["flex", "65px", "65px"]} gap="medium" margin={{ "vertical": "medium" }}>
        <Button
          label="Everyone!"
          size="small"
          secondary
          onClick={() => { setEveryone() }}
        />
        <Text weight="bold" textAlign="center">Ratio</Text>
        <Text weight="bold" textAlign="center">Cost</Text>
      </Grid>
      {props.trip.personIds.map((personId, pIdx) => (
        <Grid columns={["flex", "65px", "65px"]} gap="medium" key={personId} margin={{ "vertical": "xsmall" }}>
          <Button
            size="small"
            label={props.persons[personId] === "" ? "(name)" : props.persons[personId]}
            secondary
            primary={+getShareRatioFromPersonId(personId, props.shareRatio) !== 0}
            onClick={() => {
              const value = +getShareRatioFromPersonId(personId, props.shareRatio)
              if (value === 0) {
                props.onShareRatioChange({target: {value: 1}}, personId)
              } else {
                props.onShareRatioChange({target: {value: 0}}, personId)
              }
            }}
          />
          <MaskedInput
            name={`shareRatio[${pIdx}]`}
            mask={[
              {
                regexp: /^([0-9]$|[1-9][0-9]+$)/,
              },
              { fixed: '.' },
              {
                regexp: /^[0-9]{0,5}$/,
              }
            ]}
            value={getShareRatioFromPersonId(personId, props.shareRatio)}
            onChange={(e) => {
              props.onShareRatioChange(e, personId)
            }}
            textAlign="end"
          />
          <Text margin={{ right: "5px" }} textAlign="end" alignSelf="center" weight="bold">
            {round(calculateCost(cost, props.shareRatio, personId))}
          </Text>
        </Grid>
      ))}
      <Grid columns={["flex", "65px"]} gap="medium" >
        <Text size="small">{props.message}</Text>
        <Text margin={{ right: "5px", vertical: "small" }} textAlign="end" weight="bold" size="large">
          {round(cost)}
        </Text>
      </Grid>
    </>
  );
}

function getShareRatioFromPersonId(personId, shares) {
  for (const [p, ratio] of Object.entries(shares)) {
    if (p === personId) {
      return ratio
    }
  }
  return 0
}
