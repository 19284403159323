import { Box, Button, PageContent } from 'grommet';
import React, { useState } from 'react';
import { Calculations } from './Calculations';
import { EditExpense } from './EditExpense';

export function Trip(props) {
  const [open, setOpen] = useState(true)

  function getUnusedPersons() {
    const unused = new Set(props.trip.personIds)

    // Do not allow deleting the last person
    if (props.trip.personIds.length === 1) {
      return new Set()
    }

    // Person is participating if he's paying the receipt
    for (const receiptId of props.trip.receiptIds) {
      if (unused.has(props.receipts[receiptId].paidBy)) {
        unused.delete(props.receipts[receiptId].paidBy)
        if (unused.size === 0) return unused
      }
      // Person is participating if he's sharing any item in all receipts
      for (const itemId of props.receipts[receiptId].itemIds) {
        const shareRatioId = props.items[itemId].shareId
        for (const [personId, share] of Object.entries(props.shareRatios[shareRatioId])) {
          if (+share !== 0 && unused.has(personId)) {
            unused.delete(personId)
            if (unused.size === 0) return unused
          }
        }
      }
    }

    return unused
  }

  var receipt
  for (const receiptId of props.trip.receiptIds) {
    if (props.receipts[receiptId].itemIds.includes(props.trip.itemId)) {
      receipt = props.receipts[receiptId]
      break
    }
  }

  return (
    <PageContent direction="row-responsive" gap="medium" wrap>
      <Box margin={{ horizontal: "auto" }}>
        <Button
          id="editorShowHide"
          onClick={() => setOpen(!open)}
          label={open ? "Hide Editors" : "Show Editors"}
        />
      </Box>
      <EditExpense
        trip={props.trip}
        persons={props.persons}
        item={props.items[props.trip.itemId]}
        receipt={receipt}
        shareRatios={props.shareRatios}

        onTripChange={props.onTripChange}

        unusedPersons={getUnusedPersons()}

        onPersonAdd={props.onPersonAdd}
        onPersonNameChange={props.onPersonNameChange}
        onPersonDelete={props.onPersonDelete}

        tax={props.tax}

        onReceiptAdd={props.onReceiptAdd}
        onReceiptChange={props.onReceiptChange}

        onItemAdd={props.onItemAdd}
        onItemChange={props.onItemChange}
        onItemDelete={props.onItemDelete}

        onShareRatioChange={props.onShareRatioChange}

        open={open}
      />
      <Calculations
        trip={props.trip}
        receipts={props.receipts}
        persons={props.persons}
        tax={props.tax}
        items={props.items}
        shareRatios={props.shareRatios}
        onItemIdChange={props.onItemIdChange}
      />
    </PageContent>
  );
}