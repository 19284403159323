// import { fakeData } from './test/fakeData_v1'
// import { dataVersion, items, persons, receipts, shareRatios, tax, tripId, trips } from './test/fakeData_v2'

export function generateId() {
  return crypto.randomUUID()
}

export function fetchData() {
  // // import v1 data
  // return fakeData

  // // import v2 data
  // return {
  //   trips: trips,
  //   dataVersion: dataVersion,
  //   tax: tax,
  //   persons: persons,
  //   receipts: receipts,
  //   items: items,
  //   shareRatios: shareRatios,
  //   tripId: tripId,
  // }

  try {
    // Either dataV1 or dataV2 must be valid; otherwise it is a new user.

    // check dataVersion v1
    const dataV1 = JSON.parse(localStorage.getItem('data'))

    if (dataV1) return dataV1

    // dataV1 doesn't exist, check dataVersion v2
    // dataV2 is invalid as long as any field is null
    const dataV2 = {}
    dataV2.trips = JSON.parse(localStorage.getItem('trips'))
    if (!dataV2.trips) return null

    dataV2.dataVersion = localStorage.getItem('dataVersion')
    if (!dataV2.dataVersion) return null

    dataV2.tax = JSON.parse(localStorage.getItem('tax'))
    if (!dataV2.tax) return null

    dataV2.persons = JSON.parse(localStorage.getItem('persons'))
    if (!dataV2.persons) return null

    dataV2.receipts = JSON.parse(localStorage.getItem('receipts'))
    if (!dataV2.receipts) return null

    dataV2.items = JSON.parse(localStorage.getItem('items'))
    if (!dataV2.items) return null

    dataV2.shareRatios = JSON.parse(localStorage.getItem('shareRatios'))
    if (!dataV2.shareRatios) return null

    dataV2.tripId = localStorage.getItem('tripId')
    if (!dataV2.tripId) return null

    return dataV2
  } catch (error) {
    // Unparsable data found
    return null
  }
}

export function newData() {
  const data = {}

  data.dataVersion = 2

  data.tax = {
    [generateId()]: {
      label: "GST (+9%)",
      value: "9",
    },
    [generateId()]: {
      label: "Service Charge (+10%)",
      value: "10",
    }
  }

  const personId = generateId()
  data.persons = {
    [personId]: "",
  }

  const shareId = generateId()
  data.shareRatios = {
    [shareId]: {}
  }

  const itemId = generateId()
  data.items = {
    [itemId]: {
      name: "",
      baseCost: "",
      shareId: shareId,
    }
  }

  const receiptId = generateId()
  data.receipts = {
    [receiptId]: {
      location: "",
      paidBy: personId,
      taxes: [],
      itemIds: [itemId],
    }
  }

  const tripId = generateId()
  const date = new Date()
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  data.trips = {
    [tripId]: {
      name: "New Trip",
      desc: months[date.getMonth()] + " " + date.getFullYear(),
      itemId: itemId,
      personIds: [personId],
      receiptIds: [receiptId],
    }
  }

  data.tripId = tripId

  return data
}


export function upgradeDataVersion(data) {
  var upgraded
  if (!data.dataVersion) {
    upgraded = upgradeToV2(data)
  }

  return upgraded
}

function upgradeToV2(data) {
  const dataVersion = 2

  const tax = {}
  const taxIdMap = new Map()
  for (const option of data.tax.options) {
    const id = generateId()
    taxIdMap.set(option.id, id)
    tax[id] = {
      label: option.label,
      value: option.value,
    }
  }

  const trips = {}
  const tripIdMap = new Map()
  const persons = {}
  const items = {}
  const receipts = {}
  const shareRatios = {}
  for (const trip of data.trips) {
    const tripId = generateId()
    tripIdMap.set(trip.id, tripId)
    trips[tripId] = {
      name: trip.name,
      desc: trip.desc,
      itemId: "",
      personIds: [],
      receiptIds: [],
    }

    const personIdMap = new Map()
    for (const person of trip.persons) {
      const personId = generateId()
      personIdMap.set(person.id, personId)
      persons[personId] = person.name
      trips[tripId].personIds.push(personId)
    }

    const itemIdMap = new Map()
    for (const item of trip.items) {
      const itemTaxes = []
      for (const tax of item.taxes) {
        itemTaxes.push(taxIdMap.get(tax))
      }

      const itemId = generateId()
      itemIdMap.set(item.id, itemId)

      const shareId = generateId()
      items[itemId] = {
        name: item.name,
        baseCost: item.baseCost,
        shareId: shareId,
      }
      shareRatios[shareId] = {}
      for (const share of item.share) {
        shareRatios[shareId][personIdMap.get(share.id)] = share.ratio
      }

      // check if this item can fit in an existing receipt
      var foundSameReceipt = false
      var receiptId = ""
      for (const rId of trips[tripId].receiptIds) {
        if (receipts[rId].location !== item.location
          || receipts[rId].paidBy !== personIdMap.get(item.paidBy)
          || item.taxes.length !== receipts[rId].taxes.length) {
          continue
        }
        var sameTaxes = true
        for (const tax of item.taxes) {
          if (!receipts[rId].taxes.includes(taxIdMap.get(tax))) {
            sameTaxes = false
            break
          }
        }
        if (sameTaxes) {
          foundSameReceipt = true
          receiptId = rId
          break
        }
      }

      if (foundSameReceipt) {
        // push itemId into the fitting receipt
        receipts[receiptId].itemIds.push(itemId)
      } else {
        // push itemId into new receipt
        receiptId = generateId()
        receipts[receiptId] = {
          location: item.location,
          paidBy: personIdMap.get(item.paidBy),
          taxes: itemTaxes,
          itemIds: [itemId],
        }
        trips[tripId].receiptIds.push(receiptId)
      }
    }

    trips[tripId].itemId = itemIdMap.get(trip.items[trip.itemIdx].id)
  }

  const tripId = tripIdMap.get(data.trips[data.tripIdx].id)

  localStorage.removeItem("data")

  return {
    trips: trips,
    dataVersion: dataVersion,
    tax: tax,
    persons: persons,
    receipts: receipts,
    items: items,
    shareRatios: shareRatios,
    tripId: tripId,
  }
}