import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { Home } from './Home'
import { Import } from './Import'
import { fetchData, generateId, newData, upgradeDataVersion } from './Initialise'
import { NoMatch } from './NoMatch'

function App() {
  var data = fetchData()
  var newUser = false
  if (!data) {
    data = newData()
    newUser = true
  }

  if (!data.dataVersion || data.dataVersion < 2) {
    data = upgradeDataVersion(data)
  }

  // States relating to data
  const [trips, updateTrips] = useImmer(data.trips)
  const [tax, updateTax] = useImmer(data.tax)
  const [persons, updatePersons] = useImmer(data.persons)
  const [receipts, updateReceipts] = useImmer(data.receipts)
  const [items, updateItems] = useImmer(data.items)
  const [shareRatios, updateShareRatios] = useImmer(data.shareRatios)
  const [tripId, setTripId] = useState(data.tripId)

  useEffect(() => {
    saveData()
  })


  function saveData() {
    localStorage.setItem("trips", JSON.stringify(trips))
    localStorage.setItem("tax", JSON.stringify(tax))
    localStorage.setItem("persons", JSON.stringify(persons))
    localStorage.setItem("receipts", JSON.stringify(receipts))
    localStorage.setItem("items", JSON.stringify(items))
    localStorage.setItem("shareRatios", JSON.stringify(shareRatios))
    localStorage.setItem("tripId", tripId)
    localStorage.setItem("dataVersion", data.dataVersion)
  }


  function getReceiptIdFromItemId(itemId) {
    for (const id of trips[tripId].receiptIds) {
      if (receipts[id].itemIds.includes(itemId)) {
        return id
      }
    }
  }

  function handleTripAdd(init = false) {
    const id = generateId()
    var name = ""
    var desc = ""
    if (init) {
      const date = new Date()
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

      name = "New Trip"
      desc = months[date.getMonth()] + " " + date.getFullYear()
    }

    const personId = handlePersonAdd(false)
    const [receiptId, itemId] = handleReceiptAdd(personId, true)

    updateTrips(draft => {
      draft[id] = {
        name: name,
        desc: desc,
        itemId: itemId,
        personIds: [personId],
        receiptIds: [receiptId],
      }
    })

    setTripId(id)

    return id
  }

  function handleTripChange(newFormState) {
    updateTrips(draft => {
      draft[tripId] = newFormState
    })
  }

  function handleTripDelete() {
    // delete shares, persons, receipts, and items from this trip
    updateShareRatios(draft => {
      trips[tripId].receiptIds.forEach(receiptId => {
        receipts[receiptId].itemIds.forEach(itemId => {
          delete draft[items[itemId].shareId]
        })
      })
    })
    updateItems(draft => {
      trips[tripId].receiptIds.forEach(receiptId => {
        receipts[receiptId].itemIds.forEach(itemId => delete draft[itemId])
      })
    })
    updateReceipts(draft => {
      trips[tripId].receiptIds.forEach(receiptId => {
        delete draft[receiptId]
      })
    })
    updatePersons(draft => {
      trips[tripId].personIds.forEach(personId => delete draft[personId])
    })

    // delete the trip
    updateTrips(draft => {
      delete draft[tripId]
    })

    // if this is the only trip, generate a new default trip
    if (Object.keys(trips).length === 1) {
      const newId = handleTripAdd(true)
      setTripId(newId)
    } else {
      // switch to the trip after, unless user is deleting the last trip
      const tripList = Object.keys(trips)
      const tripIdx = tripList.findIndex(id => id === tripId)
      const newIdx = tripIdx === tripList.length - 1 ? tripIdx - 1 : tripIdx + 1
      setTripId(tripList[newIdx])
    }
  }


  function handleTaxOptionAdd(newOption = { label: "", value: "" }) {
    const id = generateId()
    // Add a new tax option, by default blank tax option
    updateTax(draft => {
      draft[id] = newOption
    })

    return id
  }

  function handleTaxOptionChange(event, taxId, field) {
    updateTax(draft => {
      draft[taxId][field] = event.target.value
    })
  }

  function handleTaxOptionDelete(taxId) {
    // tax option must be unused to execute this function
    updateTax(draft => {
      delete draft[taxId]
    })
  }


  function handlePersonAdd(toCurrentTrip = false) {
    const id = generateId()

    updatePersons(draft => {
      draft[id] = ""
    })

    if (toCurrentTrip) {
      updateTrips(draft => {
        draft[tripId].personIds.push(id)
      })
    }

    return id
  }

  function handlePersonNameChange(event, personId) {
    updatePersons(draft => {
      draft[personId] = event.target.value
    })
  }

  function handlePersonDelete(personId) {
    // all share ratios must already be 0 to execute this function.
    // delete all shares that reference this person.
    updateShareRatios(draft => {
      trips[tripId].receiptIds.forEach(receiptId => {
        receipts[receiptId].itemIds.forEach(itemId => {
          delete draft[items[itemId].shareId][personId]
        })
      })
    })

    // delete person
    updatePersons(draft => {
      delete draft[personId]
    })

    updateTrips(draft => {
      draft[tripId].personIds = draft[tripId].personIds.filter(p => personId !== p)
    })
  }


  function handleReceiptAdd(paidBy, isNewTrip = false) {
    const receiptId = generateId()
    const itemId = handleItemAdd(isNewTrip, true)

    updateReceipts(draft => {
      draft[receiptId] = {
        location: "",
        paidBy: paidBy,
        taxes: [],
        itemIds: [itemId],
      }
    })

    if (!isNewTrip) {
      updateTrips(draft => {
        draft[tripId].receiptIds.push(receiptId)
        draft[tripId].itemId = itemId
      })
    }

    return [receiptId, itemId]
  }

  function handleReceiptChange(event, field) {
    var value
    if (field === "taxes") {
      value = event.value
    } else {
      value = event.target.value
    }
    const receiptId = getReceiptIdFromItemId(trips[tripId].itemId)
    updateReceipts(draft => {
      draft[receiptId][field] = value
    })
  }


  function handleItemAdd(isNewTrip = false, isNewReceipt = false) {
    const receiptId = getReceiptIdFromItemId(trips[tripId].itemId)
    const itemId = generateId()
    const shareId = generateId()

    updateShareRatios(draft => {
      draft[shareId] = {}
    })
    updateItems(draft => {
      draft[itemId] = {
        name: "",
        baseCost: "",
        shareId: shareId,
      }
    })

    if (!isNewTrip) {
      updateTrips(draft => {
        draft[tripId].itemId = itemId
      })
    }

    if (!isNewReceipt) {
      updateReceipts(draft => {
        draft[receiptId].itemIds.push(itemId)
      })
    }

    return itemId
  }

  function handleItemChange(event, field) {
    updateItems(draft => {
      draft[trips[tripId].itemId][field] = event.target.value
    })
  }

  function handleItemDelete() {
    const itemId = trips[tripId].itemId

    // get receiptId
    const receiptId = getReceiptIdFromItemId(itemId)

    // delete item and share, and remove item from receipt
    updateShareRatios(draft => {
      delete draft[items[itemId].shareId]
    })
    updateItems(draft => {
      delete draft[itemId]
    })
    // if there is only 1 item in this receipt,
    if (receipts[receiptId].itemIds.length === 1) {
      // delete receipt
      updateReceipts(draft => {
        delete draft[receiptId]
      })
      updateTrips(draft => {
        draft[tripId].receiptIds = draft[tripId].receiptIds.filter(id => id !== receiptId)
      })

      // if there is only 1 receipt, generate a new receipt and item
      if (trips[tripId].receiptIds.length === 1) {
        const [newReceiptId, newItemId] = handleReceiptAdd(trips[tripId].personIds[0], true)
        updateTrips(draft => {
          draft[tripId].receiptIds.push(newReceiptId)
          draft[tripId].itemId = newItemId
        })
      } else {
        // set itemId to the next receipt's first item, unless user is deleting the last receipt
        const receiptList = trips[tripId].receiptIds
        const receiptIdx = receiptList.findIndex(id => id === receiptId)
        const newIdx = receiptIdx === receiptList.length - 1 ? receiptIdx - 1 : receiptIdx + 1
        const newReceiptId = trips[tripId].receiptIds[newIdx]
        updateTrips(draft => {
          draft[tripId].itemId = receipts[newReceiptId].itemIds[0]
        })
      }
    } else {
      updateReceipts(draft => {
        draft[receiptId].itemIds = draft[receiptId].itemIds.filter(id => id !== itemId)
      })
      // set itemId to the next item in the receipt, unless user is deleting the last item
      const itemList = receipts[receiptId].itemIds
      const itemIdx = itemList.findIndex(id => id === itemId)
      const newIdx = itemIdx === itemList.length - 1 ? itemIdx - 1 : itemIdx + 1
      updateTrips(draft => {
        draft[tripId].itemId = itemList[newIdx]
      })
    }
  }


  function handleItemIdChange(itemId) {
    updateTrips(draft => {
      draft[tripId].itemId = itemId
    })
  }


  // handle edits in item.share.ratio
  function handleShareRatioChange(event, personId) {
    const itemId = trips[tripId].itemId
    const shareId = items[itemId].shareId
    updateShareRatios(draft => {
      draft[shareId][personId] = event.target.value
    })
  }

  return (
    <Routes>
      <Route path="/" element={
        <Home
          tripId={tripId}
          setTripId={setTripId}

          tax={tax}
          receipts={receipts}
          trips={trips}
          persons={persons}
          items={items}
          shareRatios={shareRatios}
          dataVersion={data.dataVersion}

          onTripAdd={handleTripAdd}
          onTripChange={handleTripChange}
          onTripDelete={handleTripDelete}

          onTaxOptionAdd={handleTaxOptionAdd}
          onTaxOptionChange={handleTaxOptionChange}
          onTaxOptionDelete={handleTaxOptionDelete}

          onPersonAdd={handlePersonAdd}
          onPersonNameChange={handlePersonNameChange}
          onPersonDelete={handlePersonDelete}

          onReceiptAdd={handleReceiptAdd}
          onReceiptChange={handleReceiptChange}

          onItemAdd={handleItemAdd}
          onItemChange={handleItemChange}
          onItemDelete={handleItemDelete}

          onItemIdChange={handleItemIdChange}
          onShareRatioChange={handleShareRatioChange}
        />}
      />
      <Route path="/import" element={
        <Import
          tax={tax}
          receipts={receipts}
          persons={persons}
          items={items}
          shareRatios={shareRatios}

          updateTax={updateTax}
          updateReceipts={updateReceipts}
          updateTrips={updateTrips}
          updatePersons={updatePersons}
          updateItems={updateItems}
          updateShareRatios={updateShareRatios}
          setTripId={setTripId}

          newUser={newUser}
        />}
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )

}

export default App;
