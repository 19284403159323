import JSONCrush from "jsoncrush"
import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { generateId, upgradeDataVersion } from "./Initialise"

export function Import(props) {
  const searchParams = new URLSearchParams(window.location.search)

  const dataVersion = searchParams.get("v")
  var tax
  var trip
  var persons
  var receipts
  var items
  var shareRatios

  if (!dataVersion) {
    const tripV1 = searchParams.get("trip")
    if (!tripV1) {
      // invalid import
      throw SyntaxError("Invalid searchParams")
    }

    // v1 data

    const taxV1 = decode(searchParams.get("tax"))
    const options = []
    for (var i = 0; i < taxV1.length; i = i + 3) {
      options.push({
        id: taxV1[i],
        label: taxV1[i + 1],
        value: taxV1[i + 2],
      })
    }
    const dataV1 = {
      tripIdx: 0,
      tax: {
        options: options,
      },
      trips: [decodeTripV1(tripV1)],
    }
    const dataV2 = upgradeDataVersion(dataV1)
    tax = dataV2.tax
    trip = dataV2.trips[dataV2.tripId]
    persons = dataV2.persons
    receipts = dataV2.receipts
    items = dataV2.items
    shareRatios = dataV2.shareRatios
  } else {
    // v2 data
    tax = decode(searchParams.get("tx"))
    trip = decode(searchParams.get("tp"))
    persons = decode(searchParams.get("p"))
    receipts = decode(searchParams.get("r"))
    items = decode(searchParams.get("i"))
    shareRatios = decode(searchParams.get("s"))

  }
  trip.personIds = Object.keys(persons)
  trip.receiptIds = Object.keys(receipts)

  const [newTax, newTrip, newPersons, newReceipts, newItems, newShareRatios] = regenId(tax, trip, persons, receipts, items, shareRatios, generateId)

  newTrip.personIds = Object.keys(newPersons)
  newTrip.receiptIds = Object.keys(newReceipts)

  const id = generateId()
  useEffect(() => {
    props.setTripId(id)

    if (props.newUser) {
      props.updateTax(newTax)
      props.updateTrips({
        [id]: newTrip,
      })
      props.updatePersons(newPersons)
      props.updateReceipts(newReceipts)
      props.updateItems(newItems)
      props.updateShareRatios(newShareRatios)
    } else {
      // Check if the new tax options are duplicates
      for (const t in newTax) {
        var duplicated = false
        for (const [taxId, taxObj] of Object.entries(props.tax)) {
          if (newTax[t].label === taxObj.label && newTax[t].value === taxObj.value) {
            // duplicated. change taxId of newReceipts to existing taxId
            duplicated = true
            for (const receiptId in newReceipts) {
              newReceipts[receiptId].taxes = newReceipts[receiptId].taxes.map(tax => {
                if (tax === t) {
                  return taxId
                }
                return tax
              })
            }
            break
          }
        }
        if (!duplicated) {
          props.updateTax(draft => {
            draft[t] = newTax[t]
          })
        }
      }
      props.updateTrips(draft => {
        draft[id] = newTrip
      })
      props.updatePersons({
        ...props.persons,
        ...newPersons,
      })
      props.updateReceipts({
        ...props.receipts,
        ...newReceipts,
      })
      props.updateItems({
        ...props.items,
        ...newItems,
      })
      props.updateShareRatios({
        ...props.shareRatios,
        ...newShareRatios,
      })
    }
  })

  return (
    <Navigate to="/" replace />
  )
}

// make all data into searchParams for another user to import.
// all id should be regenerated as the trip is duplicated.
export function getShareUrl(dataVersion, tax, trip, persons, receipts, items, shareRatios) {
  // Replace ids with placeholders
  var globalId = 0
  function getId() {
    const id = globalId++
    return id.toString()
  }

  const [newTax, newTrip, newPersons, newReceipts, newItems, newShareRatios] = regenId(tax, trip, persons, receipts, items, shareRatios, getId)

  var params = new URLSearchParams({
    "v": encode(dataVersion),
    "tx": encode(newTax),
    "tp": encode(newTrip),
    "p": encode(newPersons),
    "r": encode(newReceipts),
    "i": encode(newItems),
    "s": encode(newShareRatios),
  }).toString()

  return window.location.origin + "/import?" + params
}

function regenId(tax, trip, persons, receipts, items, shareRatios, getId) {
  const newTax = {}
  const taxIdMap = new Map()
  var id
  for (const receiptId of trip.receiptIds) {
    for (const taxId of receipts[receiptId].taxes) {
      if (!taxIdMap.has(taxId)) {
        id = getId()
        taxIdMap.set(taxId, id)
        newTax[id] = tax[taxId]
      }
    }
  }

  const newPersons = {}
  const personIdMap = new Map()
  for (const personId of trip.personIds) {
    id = getId()
    personIdMap.set(personId, id)
    newPersons[id] = persons[personId]
  }

  const newShareRatios = {}
  const newItems = {}
  const itemIdMap = new Map()
  const newReceipts = {}
  const receiptIdMap = new Map()
  for (const receiptId of trip.receiptIds) {
    for (const itemId of receipts[receiptId].itemIds) {
      const shareId = getId()
      newShareRatios[shareId] = {}
      for (const pId in shareRatios[items[itemId].shareId]) {
        newShareRatios[shareId][personIdMap.get(pId)] = shareRatios[items[itemId].shareId][pId]
      }

      id = getId()
      itemIdMap.set(itemId, id)
      newItems[id] = {
        name: items[itemId].name,
        baseCost: items[itemId].baseCost,
        shareId: shareId,
      }
    }

    id = getId()
    receiptIdMap.set(receiptId, id)
    newReceipts[id] = {
      location: receipts[receiptId].location,
      paidBy: personIdMap.get(receipts[receiptId].paidBy),
      taxes: receipts[receiptId].taxes.map(taxId => taxIdMap.get(taxId)),
      itemIds: receipts[receiptId].itemIds.map(itemId => itemIdMap.get(itemId)),
    }
  }

  const newTrip = {
    name: trip.name,
    desc: trip.desc,
    itemId: itemIdMap.get(trip.itemId),
  }

  return [newTax, newTrip, newPersons, newReceipts, newItems, newShareRatios]

}

function encode(value) {
  return JSONCrush.crush(JSON.stringify(value))
}

function decode(value) {
  return value ? JSON.parse(JSONCrush.uncrush(value)) : ''
}

function decodeTripV1(b64) {
  const arr = decode(b64)

  var trip = {
    id: arr[0],
    name: arr[1],
    desc: arr[2],
    itemIdx: arr[3],
  }

  trip.persons = []
  for (var i = 0; i < arr[4].length; i = i + 2) {
    trip.persons.push({
      id: arr[4][i],
      name: arr[4][i + 1],
    })
  }

  trip.items = []
  for (i = 0; i < arr[5].length; i++) {
    var share = []

    for (var j = 0; j < arr[5][i][5].length; j = j + 2) {
      share.push({
        id: arr[5][i][5][j],
        ratio: arr[5][i][5][j + 1],
      })
    }

    trip.items.push({
      id: arr[5][i][0],
      name: arr[5][i][1],
      location: arr[5][i][2],
      baseCost: arr[5][i][3],
      paidBy: arr[5][i][4],
      share: share,
      taxes: arr[5][i][6],
    })
  }

  return trip
}
