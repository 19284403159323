import {
  Box,
  Button, Card,
  CardBody,
  CardHeader, CheckBoxGroup, Collapsible, Form, FormField, Grid, MaskedInput,
  Select, Text
} from 'grommet';
import { Add, FormTrash, User } from 'grommet-icons';
import React, { useRef, useState } from 'react';
import { isUnused, isRatioValid } from './Calculations';
import { ShareRatio } from "./ShareRatio";

export function EditExpense(props) {

  const personOptions = props.trip.personIds.map((personId) => (
    {
      label: props.persons[personId] === "" ? "(name)" : props.persons[personId],
      searchText: props.persons[personId],
      value: personId,
    }
  ))

  const taxOptions = Object.entries(props.tax).map(([taxId, tax]) => {
    return {
      id: taxId,
      label: tax.label,
      value: tax.value,
    }
  })

  const [filteredPersonOptions, setFilteredPersonOptions] = useState(null)
  const [message, setMessage] = useState("")

  const itemEl = useRef(null)

  return (
    <Box
      direction="column"
      width={props.open ? { "width": "90%", "min": "288px", "max": "750px" } : { "max": "0" }}
      margin={{ "horizontal": "auto" }}
    >
      <Card
        width={props.open ? { "width": "90%", "min": "288px", "max": "750px" } : { "max": "0" }}
        margin={{ "horizontal": "auto", "vertical": "small" }}
      >
        <Collapsible open={props.open}>
          <CardHeader>
            <Text weight="bold" size="xlarge">
              Persons
            </Text>
          </CardHeader>
          <CardBody>
            <Form
              data-testid="personsForm"
            >
              {props.trip.personIds.map((personId, pIdx) => (
                <Grid columns={["flex", "50px"]} gap="small" key={personId}>
                  <FormField
                    name={`persons[${pIdx}].name`}
                    onChange={(e) => props.onPersonNameChange(e, personId)}
                    value={props.persons[personId]}
                    placeholder="Name"
                    icon=<User />
                  />
                  <Button
                    disabled={!isUnused(personId, props.unusedPersons)}
                    onClick={() => props.onPersonDelete(personId)}
                    tip="Remove person from all expenses and contributions to delete."
                    icon=<FormTrash />
                  />
                </Grid>
              ))}
              <Box>
                <Button
                  label="Add"
                  onClick={props.onPersonAdd}
                  type="submit"
                  icon=<Add />
                />
              </Box>
            </Form>
          </CardBody>
        </Collapsible>
      </Card>
      <Card
        width={props.open ? { "width": "90%", "min": "288px", "max": "750px" } : { "max": "0" }}
        margin={{ "horizontal": "auto", "vertical": "small" }}
      >
        <Collapsible open={props.open}>
          <CardHeader>
            <Text weight="bold" size="xlarge">
              Expense
            </Text>
          </CardHeader>
          <CardBody>
            <Form
              data-testid="expenseForm"
            >
              <FormField
                name="receipt.location"
                id="receipt.location"
                htmlFor="receipt.location"
                label=<Text>Receipt Name</Text>
                value={props.receipt.location}
                onChange={(e) => {
                  props.onReceiptChange(e, "location")
                }}
              />
              <FormField
                name="item.name"
                htmlFor="item.name"
                id="item.name"
                value={props.item.name}
                label=<Text>Item</Text>
                ref={itemEl}
                onChange={(e) => {
                  props.onItemChange(e, "name")
                }}
              />
              <FormField
                label=<Text>Cost</Text>
                htmlFor="item.baseCost"
              >
                <MaskedInput
                  value={props.item.baseCost}
                  id="item.baseCost"
                  name="item.baseCost"
                  onChange={(e) => {
                    props.onItemChange(e, "baseCost")
                  }}
                  mask={[
                    {
                      regexp: /^([0-9]$|[1-9][0-9]+$)/,
                      placeholder: '123',
                    },
                    { fixed: '.' },
                    {
                      regexp: /^[0-9]{0,2}$/,
                      placeholder: '45',
                    }
                  ]}
                />
              </FormField>
              <CheckBoxGroup
                name="receipt.taxes"
                value={props.receipt.taxes}
                options={taxOptions}
                data-testid="taxOptions"
                labelKey="label"
                valueKey="id"
                direction="row-responsive"
                onChange={(e) => {
                  props.onReceiptChange(e, "taxes")
                }}
              />
              <Box direction="row" margin={{ "bottom": "medium" }}>
                <Select
                  placeholder="Who"
                  name="receipt.paidBy"
                  value={props.receipt.paidBy}
                  data-testid="whoPaid"
                  labelKey="label"
                  valueKey={{ key: "value", reduce: true }}
                  onClose={() => setFilteredPersonOptions(null)}
                  options={filteredPersonOptions ? filteredPersonOptions : personOptions}
                  searchPlaceholder="Enter name..."
                  onSearch={(text) => {
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, 'i')
                    const filtered = personOptions.filter((o) => exp.test(o.searchText))
                    setFilteredPersonOptions(filtered)
                  }}
                  onChange={(e) => {
                    props.onReceiptChange(e, "paidBy")
                  }}
                />
                <Text width="auto" margin="small" textAlign="center">
                  paid
                </Text>
              </Box>
              <ShareRatio
                trip={props.trip}
                persons={props.persons}
                shareRatio={props.shareRatios[props.item.shareId]}
                item={props.item}
                receipt={props.receipt}
                onTripChange={props.onTripChange}

                tax={props.tax}

                onShareRatioChange={props.onShareRatioChange}

                message={message}
              />
              <Box direction="row-responsive" gap="small">
                <Button
                  primary
                  type="submit"
                  icon={<Add />}
                  label="Add Item in Receipt"
                  onClick={() => {
                    if (isRatioValid(props.shareRatios[props.item.shareId])) {
                      props.onItemAdd()
                      itemEl.current.focus()
                      setMessage("")
                    } else {
                      setMessage("Excuse me, someone has to share the cost... right?")
                    }
                  }}
                  onSubmit={() => itemEl.current.focus()}
                />
                <Button
                  secondary
                  type="submit"
                  icon={<Add />}
                  label="Add Receipt"
                  onClick={() => {
                    if (isRatioValid(props.shareRatios[props.item.shareId])) {
                      props.onReceiptAdd(props.receipt.paidBy)
                      itemEl.current.focus()
                      setMessage("")
                    } else {
                      setMessage("Excuse me, someone has to share the cost... right?")
                    }
                  }}
                  onSubmit={() => itemEl.current.focus()}
                />
                <Button
                  icon={<FormTrash />}
                  label="Delete Item"
                  onClick={() => {
                    props.onItemDelete(props.trip.itemIdx)
                  }} />
              </Box>
            </Form>
          </CardBody>
        </Collapsible>
      </Card >
    </Box>
  );
}
