import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader, Grid, Tab,
  Tabs,
  Text
} from 'grommet';
import React from 'react';
// import debounce from 'lodash.debounce'

export function Calculations(props) {
  // const [res, setRes] = useState({})
  // const [transactions, setTransactions] = useState({})
  // const [expenses, setExpenses] = useState({})
  // const [items, setItems] = useState({})
  // const [contributions, setContributions] = useState({})
  // const [allItemsValid, setAllItemsValid] = useState(true)

  // const calculate = useMemo(() => debounce(() => {
  const calculate = () => {
    var id = 0
    // res is a summary of the various details of each person (in this trip).
    const res = {}
    for (const personId of props.trip.personIds) {
      res[personId] = {
        totalcont: 0,
        totalexp: 0,
        balance: 0,
        transactions: [],
        expenses: [],
        contributions: [],
      }
    }
    // transactions is an object containing 1 object per payment to be made to one another.
    const transactions = {}
    // expenses is an object containing 1 object per receipt per person that has an expense in.
    // each person's expense per receipt is a separate object inside expenses.
    const expenses = {}
    // items is an object containing 1 object per item per person.
    // each person's expense per item is a separate object inside items.
    const items = {}
    // contributions is an object containing 1 object per receipt.
    const contributions = {}
    // allItemsValid indicates whether all items are being shared by at least one person.
    var allItemsValid = true

    for (const receiptId of props.trip.receiptIds) {
      var receiptCost = 0
      for (const itemId of props.receipts[receiptId].itemIds) {
        const itemCost = addTaxes(+props.items[itemId].baseCost, props.receipts[receiptId].taxes, props.tax)
        receiptCost += itemCost

        const share = props.shareRatios[props.items[itemId].shareId]
        var totalRatio = 0
        for (const [personId, ratio] of Object.entries(share)) {
          // totalRatio is used to check that at least someone is sharing the item
          totalRatio += +ratio
          const cost = calculateCost(itemCost, share, personId)
          if (cost > 0) {
            items[id.toString()] = {
              itemId: itemId,
              cost: cost,
            }

            // check whether itemId comes from a receipt that already has a record for this person.
            var foundSameReceipt = false
            for (const expenseId of res[personId].expenses) {
              if (expenses[expenseId].receipt === receiptId) {
                // item belongs to a receipt that is already recorded. add the item to the same record.
                expenses[expenseId].itemIds.push(id.toString())
                expenses[expenseId].cost += cost
                foundSameReceipt = true
                break
              }
            }
            if (!foundSameReceipt) {
              // add a new record of the receipt and the item.
              expenses[id.toString()] = {
                receipt: receiptId,
                itemIds: [id.toString()],
                cost: cost,
              }
              res[personId].expenses.push(id.toString())
            }

            res[personId].totalexp += cost
            id++
          }
        }
        if (totalRatio === 0) {
          // setAllItemsValid(false)
          allItemsValid = false
        }
      }

      contributions[id.toString()] = {
        receipt: receiptId,
        cost: receiptCost,
      }

      res[props.receipts[receiptId].paidBy].contributions.push(id.toString())
      res[props.receipts[receiptId].paidBy].totalcont += receiptCost
      id++
    }

    // The following section calculates who owes who how much.
    var balances = []
    for (const personId of props.trip.personIds) {
      res[personId].balance = res[personId].totalcont - res[personId].totalexp
      balances.push(round(res[personId].balance))
    }

    // A pair whose balance adds to zero should settle with each other.
    for (var i = 0; i < balances.length - 1; i++) {
      for (var j = i + 1; j < balances.length; j++) {
        if (balances[i] + balances[j] === 0) {
          if (balances[i] > 0) {
            transactions[id.toString()] = {
              payer: props.trip.personIds[j],
              receiver: props.trip.personIds[i],
              amount: balances[i],
            }

            res[props.trip.personIds[i]].transactions.push(id.toString())
            res[props.trip.personIds[j]].transactions.push(id.toString())
            balances[i] = 0
            balances[j] = 0
            id++
          } else if (balances[j] > 0) {
            transactions[id.toString()] = {
              payer: props.trip.personIds[i],
              receiver: props.trip.personIds[j],
              amount: balances[j],
            }

            res[props.trip.personIds[i]].transactions.push(id.toString())
            res[props.trip.personIds[j]].transactions.push(id.toString())
            balances[i] = 0
            balances[j] = 0
            id++
          }
        }
      }
    }

    // No more pairs. All negative balances to pay iteratively
    i = 0
    j = 0
    var amount = 0

    // find first positive balance
    while (i < balances.length && balances[i] <= 0) {
      i++
    }

    // find first negative balance
    while (j < balances.length && balances[j] >= 0) {
      j++
    }

    while (i < balances.length && j < balances.length) {
      amount = Math.min(balances[i], -balances[j])
      transactions[id.toString()] = {
        payer: props.trip.personIds[j],
        receiver: props.trip.personIds[i],
        amount: amount,
      }

      res[props.trip.personIds[i]].transactions.push(id.toString())
      res[props.trip.personIds[j]].transactions.push(id.toString())
      balances[i] = round(balances[i] - amount)
      balances[j] = round(balances[j] + amount)
      id++

      // find next positive balance
      while (i < balances.length && balances[i] <= 0) {
        i++
      }

      // find next negative balance
      while (j < balances.length && balances[j] >= 0) {
        j++
      }
    }

    return [res, transactions, expenses, items, contributions, allItemsValid]
    //   setRes(res)
    //   setTransactions(transactions)
    //   setExpenses(expenses)
    //   setItems(items)
    //   setContributions(contributions)
    //   setAllItemsValid(allItemsValid)

    // }, 500), [
    //   props.trip,
    //   props.receipts,
    //   props.tax,
    //   props.items,
    //   props.shareRatios,
    //   allItemsValid,
    // ])

    // useEffect(() => {
    //   calculate()

    //   return () => {
    //     calculate.cancel()
    //   }
    // }, [calculate, props])

    // var loading = false
    // for (const personId of props.trip.personIds) {
    //   if (!Object.keys(res).includes(personId)) {
    //     loading = true
    //     break
    //   }
    // }

    // if (loading) {
    //   return (
    //     <Tabs justify="center" flex="grow" margin={{ "horizontal": "small" }}>
    //       {/* <Tab title="Summary" id="summary" />
    //       <Tab title="Expenses" id="expenses" />
    //       <Tab title="Who Paid" id="whopaid" /> */}
    //       <Spinner />
    //     </Tabs>
    //   )
    // } else {
  }

  const [res, transactions, expenses, items, contributions, allItemsValid] = calculate()

  return (
    <Tabs justify="center" flex="grow" margin={{ "horizontal": "small" }}>
      <Tab title="Summary" id="summary">
        <Grid margin="small" columns={{ "size": ["288px", "flex"] }} gap="medium">
          {props.trip.personIds.map((personId) => (
            <Card data-testid="personCard" hoverIndicator={false} direction="column" key={personId}>
              <CardHeader align="center" direction="row" flex={false} justify="between" gap="medium" pad="small">
                <Text weight="bold" size="large">
                  {props.persons[personId]}
                </Text>
              </CardHeader>
              <CardBody pad="small" direction="column">
                <Card>
                  <Grid
                    margin={{ "horizontal": "xsmall" }}
                    columns={["flex", "65px"]}
                    key="paidFor"
                  >
                    <Text>
                      Paid
                    </Text>
                    <Text textAlign="end" weight="bold">
                      {round(res[personId].totalcont)}
                    </Text>
                  </Grid>
                  <Grid
                    margin={{ "horizontal": "xsmall" }}
                    columns={["flex", "65px"]}
                    key="totalExpenses"
                  >
                    <Text>
                      Expenses
                    </Text>
                    <Text textAlign="end" weight="bold">
                      {round(res[personId].totalexp)}
                    </Text>
                  </Grid>
                  <Grid
                    margin={{ "horizontal": "xsmall" }}
                    columns={["flex", "65px"]}
                    key="balance"
                  >
                    <Text>
                      Balance
                    </Text>
                    <Text textAlign="end" weight="bold">
                      {round(res[personId].balance)}
                    </Text>
                  </Grid>
                </Card>
                <Card margin={{ "top": "medium" }}>
                  {res[personId].transactions.map((transactionId) => (
                    <Grid
                      direction="row-responsive"
                      margin={{ "horizontal": "xsmall" }}
                      columns={["flex", "100px"]}
                      key={transactionId}
                      overflow="auto"
                    >
                      <Text margin={{ "horizontal": "small", "vertical": "xsmall" }} weight="bold" size="large" wordBreak="break-word">
                        {'💸'}{personId === transactions[transactionId].payer ? ' ⇨ ' + props.persons[transactions[transactionId].receiver] : ' ⇦ ' + props.persons[transactions[transactionId].payer]}
                      </Text>
                      <Text margin={{ "horizontal": "small", "vertical": "xsmall" }} weight="bold" size="large" textAlign="end">
                        {transactions[transactionId].amount}
                      </Text>
                    </Grid>
                  ))}
                </Card>
              </CardBody>
            </Card>
          ))}
        </Grid>
        {!allItemsValid &&
          <Box width="large">
            <Card>
              <Text weight="bold" margin="small">
                The numbers may not tally because there is an item that nobody is sharing.<br />
                See item(s) in pink in "Who Paid" tab.
              </Text>
            </Card>
          </Box>
        }
      </Tab>
      <Tab title="Expenses" id="expenses">
        <Grid margin="small" columns={{ "size": ["288px", "flex"] }} gap="medium">
          {props.trip.personIds.map((personId) => res[personId].expenses.length > 0 && (
            <Card data-testid="personCard" hoverIndicator={false} direction="column" key={personId}>
              <CardHeader align="center" direction="row" flex={false} justify="between" gap="medium" pad="small">
                <Text weight="bold" size="large">
                  {props.persons[personId]}
                </Text>
              </CardHeader>
              <CardBody pad="small" direction="column">
                {res[personId].expenses.map((expenseId) => {
                  const itemIds = expenses[expenseId].itemIds
                  const receiptId = expenses[expenseId].receipt
                  return (
                    <React.Fragment
                      key={expenseId}
                    >
                      <Button
                        data-testid="receipt"
                        onClick={() => {
                          props.onItemIdChange(items[itemIds[0]].itemId)
                        }}
                      >
                        <Grid
                          margin={{ "horizontal": "xsmall" }}
                          columns={["flex", "65px"]}
                        >
                          <Text weight="bold" data-testid="receipt.location">
                            {props.receipts[receiptId].location}
                          </Text>
                          <Text textAlign="end" weight="bold">
                            {round(expenses[expenseId].cost)}
                          </Text>
                        </Grid>
                      </Button>
                      <Card margin={{ "bottom": "medium" }}>
                        {itemIds.map((itemId) => (
                          <Button
                            key={itemId}
                            data-testid="item"
                            onClick={() => {
                              props.onItemIdChange(items[itemId].itemId)
                            }}
                          >
                            <Grid
                              margin={{ "horizontal": "xsmall" }}
                              columns={["flex", "65px"]}
                            >
                              <Text data-testid="item.name">
                                {props.items[items[itemId].itemId].name}
                              </Text>
                              <Text textAlign="end">
                                {round(items[itemId].cost)}
                              </Text>
                            </Grid>
                          </Button>
                        ))}
                      </Card>
                    </React.Fragment>
                  )
                })}
                <Text margin={{ "horizontal": "xsmall", "top": "small" }} weight="bold" size="large" textAlign="end">
                  {round(res[personId].totalexp)}
                </Text>
              </CardBody>
            </Card>
          ))}
        </Grid>
      </Tab>
      <Tab title="Who Paid" id="whopaid">
        <Grid margin="small" columns={{ "size": ["288px", "flex"] }} gap="medium">
          {props.trip.personIds.map((personId) => res[personId].contributions.length > 0 && (
            <Card data-testid="personCard" hoverIndicator={false} direction="column" key={personId}>
              <CardHeader align="center" direction="row" flex={false} justify="between" gap="medium" pad="small">
                <Text weight="bold" size="large">
                  {props.persons[personId]}
                </Text>
              </CardHeader>
              <CardBody pad="small" direction="column">
                {res[personId].contributions.map((contributionId) => {
                  const receiptId = contributions[contributionId].receipt
                  return (
                    <React.Fragment
                      key={receiptId}
                    >
                      <Button
                        data-testid="receipt"
                        onClick={() => {
                          props.onItemIdChange(props.receipts[receiptId].itemIds[0])
                        }}
                      >
                        <Grid
                          margin={{ "horizontal": "xsmall" }}
                          columns={["flex", "65px"]}
                        >
                          <Text weight="bold" data-testid="receipt.location">
                            {props.receipts[receiptId].location}
                          </Text>
                          <Text textAlign="end" weight="bold">
                            {round(contributions[contributionId].cost)}
                          </Text>
                        </Grid>
                      </Button>
                      <Card margin={{ "bottom": "medium" }}>
                        {props.receipts[receiptId].itemIds.map((itemId) =>
                          <Button
                            key={itemId}
                            data-testid="item"
                            onClick={() => {
                              props.onItemIdChange(itemId)
                            }}
                          >
                            <Grid
                              key={itemId}
                              margin={{ "horizontal": "xsmall" }}
                              columns={["flex", "65px"]}
                            >
                              <Text color={isRatioValid(props.shareRatios[props.items[itemId].shareId]) ? "" : "pink"} data-testid="item.location">
                                {props.items[itemId].name}
                              </Text>
                              <Text color={isRatioValid(props.shareRatios[props.items[itemId].shareId]) ? "" : "pink"} textAlign="end">
                                {round(addTaxes(+props.items[itemId].baseCost, props.receipts[receiptId].taxes, props.tax))}
                              </Text>
                            </Grid>
                          </Button>
                        )}
                      </Card>
                    </React.Fragment>
                  )
                })}
                <Text margin={{ "horizontal": "xsmall", "top": "small" }} weight="bold" size="large" textAlign="end">
                  {round(res[personId].totalcont)}
                </Text>
              </CardBody>
            </Card>
          ))}
        </Grid>
      </Tab>
    </Tabs>
  )
}
// }

export function round(num) {
  return +(`${Math.round(num + "e+2")}e-2`);
}

export function addTaxes(baseCost, taxes, taxOptions) {
  var cost = baseCost

  for (const tax of taxes) {
    cost *= (1 + taxOptions[tax].value / 100)
  }

  return cost
}

export function isRatioValid(share) {
  var total = 0
  for (const ratio of Object.values(share)) {
    total += +ratio
  }

  return (total > 0)
}

export function isUnused(id, unused) {
  return unused.has(id)
}

// Calculate how much personId should pay for, given an item cost.
export function calculateCost(cost, share, personId) {
  var totalShares = 0
  var myShare = 0

  for (const [p, r] of Object.entries(share)) {
    var ratio = +r
    totalShares += ratio

    if (personId === p) {
      myShare = ratio
    }
  }

  if (totalShares > 0) {
    return myShare * cost / totalShares
  }
  return 0
}